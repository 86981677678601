/****** MEDIA QUERIES ******

0    - 760px  :   xs
761  - 1024px  :  sm
1025 - 1440px :   md
1441 - 1920px :   lg (default)
>1920px       :   xl

1em = 16px (default browser font-size)

$breakpoint args: xs, sm, md, lg, xl

ORDER: base > typography > general layout (header, footer, nav, grid) > page-specific layout > components

******/
@import url("https://fonts.googleapis.com/css?family=EB+Garamond:400,700|Montserrat:100,200,300,400");
.u-container-50 {
  width: 50%;
  margin: auto; }
  @media only screen and (max-width: 64em) {
    .u-container-50 {
      width: 80%; } }
  @media only screen and (max-width: 40em) {
    .u-container-50 {
      width: 100%; } }

.u-container-75 {
  width: 75%;
  margin: auto; }

[data-aos] {
  pointer-events: auto; }

[data-aos="darken"] {
  transition-property: color; }
  [data-aos="darken"].aos-animate {
    color: #414b54; }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/**
 * Set up a decent box model on the root element
 */
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: 125%; }
  @media only screen and (min-width: 121em) {
    html {
      font-size: 150%; } }
  @media only screen and (max-width: 90em) {
    html {
      font-size: 112.5%; } }
  @media only screen and (max-width: 64em) {
    html {
      font-size: 100%; } }

/**
   * Make all elements from the DOM inherit from the parent box-sizing
   * Since `*` has a specificity of 0, it does not override the `html` value
   * making all elements inheriting from the root box-sizing value
   * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
   */
*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth; }

a {
  text-decoration: none;
  color: #cbc5c0; }

ul {
  padding: 0; }

li {
  list-style: none; }

::selection {
  color: #7f7062;
  background-color: #cbc5c0; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-style: normal; }

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button;
  /* for input */
  -webkit-user-select: none;
  /* for button */
  -moz-user-select: none;
  -ms-user-select: none; }

body {
  font-family: 'EB Garamond', serif;
  color: #cbc5c0;
  line-height: 1.5; }

.on-light-bg {
  color: #160d0d; }

.text-link:link, .text-link:visited {
  font-weight: 700;
  transition: color .2s; }

.text-link:hover, .text-link:focus {
  color: #7f7062; }

ul li {
  margin-bottom: .5em; }

.heading-main {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 1.8em;
  color: inherit;
  margin-bottom: 1.5em; }
  .heading-main.on-light-bg {
    color: #414b54; }
  @media only screen and (max-width: 40em) {
    .heading-main {
      font-size: 1.5em;
      margin-bottom: .75em; } }

.heading-sub {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
  color: #7f7062;
  letter-spacing: .2em; }

.heading-small {
  font-size: 0.7rem;
  text-transform: uppercase;
  color: #7f7062;
  letter-spacing: .1em;
  margin-bottom: 0.2em; }

.paragraph {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1em;
  hyphens: auto; }

.footnote {
  font-size: 0.85rem;
  color: #7f7062; }

.margin-small {
  margin-top: -.5rem; }

.footer {
  width: 100%;
  height: 8rem;
  background-color: #414b54;
  padding-top: 1em; }
  .footer__content {
    font-size: 0.85rem;
    text-align: center;
    line-height: 1.3; }

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  z-index: 1000;
  display: flex;
  justify-content: center; }
  .navigation__block {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1.5rem; }
    @media only screen and (max-width: 40em) {
      .navigation__block {
        justify-content: space-between;
        width: 88%; } }
  .navigation__link {
    font-weight: 400;
    font-size: 0.85rem;
    text-transform: uppercase;
    letter-spacing: .1em;
    margin-left: 2rem;
    margin-right: 2rem; }
    @media only screen and (max-width: 40em) {
      .navigation__link {
        font-size: .75rem;
        margin-left: 0;
        margin-right: 0; } }
    .navigation__link a:link,
    .navigation__link a:visited {
      display: block;
      transform: translateY(0);
      transition: all .2s; }
      .navigation__link a:link.on-light-bg,
      .navigation__link a:visited.on-light-bg {
        color: #414b54; }
    .navigation__link a:hover,
    .navigation__link a:active {
      color: #7f7062;
      transform: translateY(-2px);
      text-decoration: none; }

.btn-primary {
  font-family: 'EB Garamond', serif;
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: .2em;
  color: #cbc5c0;
  background-color: #7f7062;
  display: inline-block;
  padding: .8rem 1.2rem;
  margin-top: 2rem;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: inset 0 1px 0 #9b8c7d, 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all .3s; }
  .btn-primary:hover, .btn-primary:active {
    box-shadow: none;
    background-color: #7c6d60;
    text-decoration: none;
    color: inherit; }
  @media only screen and (max-width: 40em) {
    .btn-primary {
      margin-top: 1rem; } }

.btn-secondary {
  font-family: 'EB Garamond', serif;
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: .2em;
  color: #7f7062;
  padding: .6rem .8rem;
  margin-top: 2rem;
  border-radius: 8px;
  border: 1px solid #7f7062;
  cursor: pointer;
  display: inline-block;
  transition: all .2s; }
  .btn-secondary:hover, .btn-secondary:active {
    color: #7f7062;
    text-decoration: none;
    border-style: dotted;
    background-color: rgba(203, 197, 192, 0.7); }

form {
  width: 70%;
  margin: 2rem auto 0;
  text-align: left; }
  @media only screen and (max-width: 40em) {
    form {
      margin-top: 1rem;
      width: 100%; } }

input {
  width: 100%;
  padding: .2em 2%;
  font-family: 'EB Garamond', serif;
  font-size: 1rem;
  margin-bottom: 1em;
  border: none; }

textarea {
  width: 100%;
  height: 12rem;
  padding: .2em 2%;
  font-family: 'EB Garamond', serif;
  font-size: 1rem;
  margin-bottom: .5em;
  border: none; }

button {
  font-family: 'EB Garamond', serif;
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: .2em;
  color: #cbc5c0;
  background-color: #414b54;
  float: right;
  margin-top: 0;
  padding: .4rem .8rem;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: inset 0 1px 0 #414b54, 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all .3s; }
  button:hover, button:active {
    box-shadow: none;
    background-color: rgba(65, 75, 84, 0.92); }

.section-home {
  width: 100%;
  height: 100%;
  background-color: #160d0d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative; }
  .section-home__bg {
    width: 100%;
    height: 100%;
    background-color: #160d0d;
    background-image: url(/img/coffee-beans_1936.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    animation-name: fade-in;
    animation-iteration-count: 1;
    animation-duration: .4s;
    animation-fill-mode: forwards; }
    @media only screen and (min-width: 121em) {
      .section-home__bg {
        background-image: url(/img/coffee-beans_2560.jpg); } }
    @media only screen and (max-width: 90em) {
      .section-home__bg {
        background-image: url(/img/coffee-beans_1440.jpg); } }
    @media only screen and (max-width: 64em) {
      .section-home__bg {
        background-image: url(/img/coffee-beans_1024.jpg); } }
    @media only screen and (max-width: 40em) {
      .section-home__bg {
        background-image: url(/img/coffee-beans_760.jpg); } }
  .section-home__content {
    padding: 5rem 2rem 4rem;
    background-color: rgba(65, 75, 84, 0.92);
    outline: 3px solid #7f7062;
    outline-offset: 4px;
    text-align: center;
    width: 34rem;
    margin: auto;
    z-index: 900; }
    @media only screen and (max-width: 40em) {
      .section-home__content {
        width: 88%;
        padding: 3rem 2rem 2.5rem; } }
  .section-home .logo {
    width: 45%; }
    @media only screen and (max-width: 40em) {
      .section-home .logo {
        width: 60%; } }
  .section-home .tagline {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5em;
    line-height: 1.2;
    font-weight: 300;
    color: #7f7062;
    margin-top: .5em;
    margin-bottom: 3rem; }
    @media only screen and (max-width: 40em) {
      .section-home .tagline {
        font-size: 1rem;
        margin-bottom: 2rem; } }
  .section-home__gradient {
    width: 100%;
    height: 20vh;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(transparent, #160d0d); }

.section-beans {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #160d0d; }
  .section-beans__content {
    text-align: center;
    padding: 6rem 0;
    z-index: 900; }
  .section-beans .card-block {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .section-beans .card-block__card {
      width: 18rem;
      min-height: 23rem;
      border: 1px solid #7f7062;
      margin: 1rem;
      padding: 11rem 1.3rem 1.3rem;
      text-align: left;
      background-color: #160d0d;
      background-repeat: no-repeat;
      background-position: top;
      background-size: contain; }
    .section-beans .card-block .gayo {
      background-image: url(/img/bg-motif-gayo.svg); }
    .section-beans .card-block .kalossi {
      background-image: url(/img/bg-motif-kalossi.svg); }
    .section-beans .card-block .kintamani {
      background-image: url(/img/bg-motif-kintamani.svg); }

.section-roasters {
  width: 100%;
  min-height: 100vh;
  background-color: rgba(203, 197, 192, 0.2); }
  .section-roasters__content {
    width: 58em;
    margin: auto;
    padding-top: 10rem;
    padding-bottom: 10rem;
    max-width: 90vw;
    text-align: center; }
    @media only screen and (max-width: 64em) {
      .section-roasters__content {
        width: 90vw;
        padding-top: 5rem;
        padding-bottom: 5rem; } }

.section-contact {
  width: 100%;
  min-height: 100%;
  padding-top: 10rem;
  padding-bottom: 10rem;
  background-color: white;
  background-image: url(/img/coffee-beans-pattern-15.png);
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media only screen and (max-width: 40em) {
    .section-contact {
      padding-top: 5rem;
      padding-bottom: 5rem; } }
  .section-contact__content {
    padding: 3rem;
    background-color: rgba(203, 197, 192, 0.7);
    outline: 3px solid #cbc5c0;
    outline-offset: 3px;
    text-align: center;
    width: 34rem;
    margin: auto;
    z-index: 900; }
    @media only screen and (max-width: 40em) {
      .section-contact__content {
        padding: 2rem 1.5rem;
        width: 88%; } }
